import firebase from "firebase/app";
import "firebase/firestore";

let config = {
  apiKey: "AIzaSyDGLycrJCOdKLb9XhHgUj8i3AGD-3rnCfQ",
  authDomain: "bezkoder-firebase-a6c47.firebaseapp.com",
  databaseURL: "https://bezkoder-firebase.firebaseio.com",
  projectId: "bezkoder-firebase-a6c47",
  storageBucket: "bezkoder-firebase-a6c47.appspot.com",
  messagingSenderId: "155595043250",
  appId: "1:155595043250:web:ba398d389e09901654f4ab"
};

firebase.initializeApp(config);

export default firebase.firestore();
